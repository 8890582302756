import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh";
import router from "../router"


const instance = axios.create({
 baseURL: '',
 headers: {
 'Accept': 'application/json',
 'Content-Type': 'application/json',
 'common': "",
 }
});

const refreshAuthLogic = failedRequest =>
  instance
    .post(process.env.VUE_APP_API_URL+"/token/refresh", {
      refresh: localStorage.getItem("refresh_token")
    })
    .then(tokenRefreshResponse => {
      console.log(tokenRefreshResponse);
      localStorage.setItem("innotoken", tokenRefreshResponse.data.token);
      localStorage.setItem("refresh_token", tokenRefreshResponse.data.refresh_token);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token;
      return Promise.resolve();
    })
    .catch(error => {
      console.log("refresh fail");
      localStorage.removeItem("innotoken");
      localStorage.removeItem("refresh_token");
      //pushToLogin();

      if(router.currentRoute._value.name != "Confirm your account" && router.currentRoute._value.name != "login")router.push("/login");
      return Promise.reject(error);
    });
createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
