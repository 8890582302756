import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import '@mdi/font/css/materialdesignicons.css'
import VueEasyLightbox from 'vue-easy-lightbox'
import axios from 'axios'
import VueAxios from 'vue-axios'
import setupInterceptors from './tools/interceptor';
import CanvasJSChart from '@canvasjs/vue-charts';
 import ReadMore from 'vue-read-more';
import { createI18n } from 'vue-i18n';
 import VueTelInput from 'vue-tel-input';
 import 'vue-tel-input/vue-tel-input.css';
import en from './locales/en.json';

setupInterceptors(store);

require('@/assets/css/bootstrap.min.css')
loadFonts()

console.log(store);

const messages = {
  en: en,
};
const savedLocale = localStorage.getItem('locale') || 'en';
// Create vue-i18n instance with options
const i18n = createI18n({
  locale: savedLocale, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});


const app = createApp(App);
  app.use(router)
  .use(store)
  .use(vuetify)
  .use(CanvasJSChart)
  .use(VueEasyLightbox)
  .use(VueAxios, axios)
  .use(ReadMore)
  .use(VueTelInput)
  .use(i18n)
  .mount('#app')

  app.config.globalProperties.$filters = {
    beautyDict(value) {
      if (!value) return ''
      value = value.toString()
      value = value.replace("dict_","").replaceAll("_"," ")
      if(value.toUpperCase() == "IP")value = "IP";
      if(value.toUpperCase() == "KEY EXECTION FACTORS")value = "Key execution factors";
      if(value.toUpperCase() == "KEY EXECTION FACTORS STARTUPS")value = "Key execution factors startups";
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    formatDateUS(dateString) {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        // Then specify how you want your dates to be formatted
        return new Intl.DateTimeFormat('fr',{
  dateStyle: "short",
}).format(date);
}else{
  return dateString;
}
    }
  }
