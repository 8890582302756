<template lang="html">
  <div class="sidebar_menu" v-if="role == 'Admin'">
    <router-link to="/admin/my_projects" class="menu_link mainMenuLink" >
      <!-- <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img admi" alt=""> -->
      <span class="menu_title">Projects</span>
    </router-link>
        <div class="projectListMenu">
          <div class="projectListItem">
            <div class="submenu_link withToggler">
              <span class="small_menu_title level_1"  @click="toggleProject = !toggleProject" >All projects</span>
              <img :src="require('@/assets/open.svg')" @click="toggleProject = true" alt="" v-if="toggleProject == false">
              <img :src="require('@/assets/close.svg')" @click="toggleProject = false" alt="" v-if="toggleProject == true">

            </div>
          </div>
                <div class="projectListSubMenu"  v-if="toggleProject == true">
    <div class="projectListItem " v-for="(project, p) in myProjects" :key="p">
      <router-link :to="'/admin/project/'+project._id.$oid" class="submenu_link" v-if="project._id"  :class="(project._id.$oid == activeProject) ? 'submenu_link active_link_by_project' : 'submenu_link'">
        <span class="small_menu_title">{{project.name.toUpperCase()}}</span>
      </router-link>
    </div>
  </div>

  </div>

  <div class="menu_link mainMenuLink" >
    <!-- <img :src="require('@/assets/assessMenu.svg')" class="menu_icon_img admi" alt=""> -->
    <span class="menu_title">  {{$t("AssessMenu")}}</span>
  </div>


  <div class="projectListMenu">
    <div class="projectListItem">
    <div class="submenu_link withToggler">
        <span class="small_menu_title level_1"  @click="toggleAssessment = !toggleAssessment" >{{$t("Startup assessments")}}</span>
        <img :src="require('@/assets/open.svg')" @click="toggleAssessment = true" alt="" v-if="toggleAssessment == false">
        <img :src="require('@/assets/close.svg')" @click="toggleAssessment = false" alt="" v-if="toggleAssessment == true">

      </div>
    </div>
                    <div class="projectListSubMenu"  v-if="toggleAssessment == true">
                      <div class="projectListMenu">


    <div class="projectListItem ">
      <router-link to="/admin/manage_assessments" class="submenu_link">
        <span class="small_menu_title ">{{$t("Manage assessments")}}</span>
      </router-link>
    </div>
    <div class="projectListItem " >
      <router-link to="/admin/internalassessment_creation" class="submenu_link" >
        <span class="small_menu_title">{{$t("Create an assessment")}}</span>
      </router-link>
    </div>
    <div class="projectListItem " >
      <router-link to="/admin/manage_assessments_criterias" class="submenu_link" >
        <span class="small_menu_title">{{$t("Manage criterias")}}</span>
      </router-link>
    </div>
    <div class="projectListItem " >
      <router-link to="/admin/manage_users_criterias" class="submenu_link" >
        <span class="small_menu_title">{{$t("Make criterias public")}}</span>
      </router-link>
    </div>

    <div class="projectListItem " >
      <router-link to="/admin/manage_next_steps" class="submenu_link" >
        <span class="small_menu_title">{{$t("Manage next steps")}}</span>
      </router-link>
    </div>
    <div class="projectListItem " >
      <router-link to="/admin/manage_default_data/6707d104621213a6cb50d58e" class="submenu_link" >
        <span class="small_menu_title">{{$t("Default mail to judges")}}</span>
      </router-link>
    </div>
                    </div>
</div>
  </div>

    <div class="menu_link mainMenuLink" >
      <!-- <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img admi" alt=""> -->
      <span class="menu_title">Administration</span>
    </div>

    <div class="projectListMenu">
    <div class="projectListItem">
    <div class="submenu_link withToggler">
        <span class="small_menu_title level_1"  @click="toogleAdminPan = !toogleAdminPan" >{{$t("Admin panel")}}</span>
        <img :src="require('@/assets/open.svg')" @click="toogleAdminPan = true" alt="" v-if="toogleAdminPan == false">
        <img :src="require('@/assets/close.svg')" @click="toogleAdminPan = false" alt="" v-if="toogleAdminPan == true">

      </div>
    </div>
                        <div class="projectListSubMenu"  v-if="toogleAdminPan == true">
        <div class="projectListMenu">
          <div class="projectListItem ">
            <router-link to="/admin/manage_users_admin" class="submenu_link">
              <span class="small_menu_title ">Manage admins</span>
            </router-link>
          </div>
    <div class="projectListItem ">
      <router-link to="/register_admin" class="submenu_link">
        <span class="small_menu_title ">Add an expert</span>
      </router-link>
    </div>
    <div class="projectListItem ">
      <router-link to="/admin/all_projects" class="submenu_link">
        <span class="small_menu_title ">Assign projects</span>
      </router-link>
    </div>
    <div class="projectListItem ">
      <router-link to="/admin/manage_users" class="submenu_link">
        <span class="small_menu_title ">Manage customers</span>
      </router-link>
    </div>
    <div class="projectListItem ">
      <router-link to="/register" class="submenu_link">
        <span class="small_menu_title ">Add a customer</span>
      </router-link>
    </div>

    <div class="projectListItem ">
      <router-link to="/admin/dict" class="submenu_link">
        <span class="small_menu_title ">Manage taxonomies</span>
      </router-link>
    </div>

    <div class="projectListItem ">
      <router-link to="/admin/all_startups" class="submenu_link">
        <span class="small_menu_title ">Manage startups</span>
      </router-link>
    </div>
    <div class="projectListItem ">
      <router-link to="/admin/partial_startups" class="submenu_link">
        <span class="small_menu_title ">Profiles in progress</span>
      </router-link>
    </div>
  </div>
  </div>
  </div>

  <router-link to="/all_my_projects" class="innopearl_topbar_link account_responsive">
    <img :src="require('@/assets/header_my_account.svg')" alt=""/>
    <span class="username">{{fullname}}</span>
  </router-link>
  </div>
  <div class="sidebar_menu" v-else-if="role == 'Expert'">
    <router-link to="/expert/my_projects" class="menu_link" >
      <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">Projects</span>
    </router-link>
      <!-- <div class="projectListMenu">
        <div class="projectListItem">
          <span to="/expert/my_projects" class="submenu_link">
            <span class="small_menu_title">All projects</span>
          </span>
        </div>
    <div class="projectListItem " v-for="(project, p) in myProjects" :key="p">
      <router-link :to="'/expert/project/'+project._id.$oid"  :class="(project._id.$oid == activeProject) ? 'submenu_link active_link_by_project' : 'submenu_link'" v-if="project._id" >
        <span class="small_menu_title">{{project.name.toUpperCase()}}</span>
      </router-link>
    </div>
  </div> -->
  <div class="projectListMenu">
    <div class="projectListItem">
      <div class="submenu_link withToggler">
        <span class="small_menu_title level_1"  @click="toggleProject = !toggleProject" >All projects</span>
        <img :src="require('@/assets/open.svg')" @click="toggleProject = true" alt="" v-if="toggleProject == false">
        <img :src="require('@/assets/close.svg')" @click="toggleProject = false" alt="" v-if="toggleProject == true">

      </div>
    </div>
          <div class="projectListSubMenu"  v-if="toggleProject == true">
<div class="projectListItem " v-for="(project, p) in myProjects" :key="p">
<router-link :to="'/expert/project/'+project._id.$oid" class="submenu_link" v-if="project._id"  :class="(project._id.$oid == activeProject) ? 'submenu_link active_link_by_project' : 'submenu_link'">
  <span class="small_menu_title">{{project.name.toUpperCase()}}</span>
</router-link>
</div>
</div>

      <div class="projectListItem">
        <router-link to="/expert/my_projects" class="submenu_link">
          <span class="small_menu_title level_1">My dashboard</span>
        </router-link>
      </div>
</div>

  <router-link to="/all_my_projects" class="innopearl_topbar_link account_responsive">
    <img :src="require('@/assets/header_my_account.svg')" alt=""/>
    <span class="username">{{fullname}}</span>
  </router-link>




<div class="menu_link " >
  <img :src="require('@/assets/assessMenu.svg')" class="menu_icon_img" alt="">
  <span class="menu_title">Assessment</span>
</div>
<div class="projectListMenu">
  <div class="projectListItem">
    <router-link to="/expert/manage_assessments" class="submenu_link">
      <span class="small_menu_title">{{$t("Manage assessments")}}</span>
    </router-link>
  </div>


</div>
  </div>




  <div class="sidebar_menu"  v-else>
    <div class="menu_link" >
      <img :src="require('@/assets/targetMenu.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">{{$t("Target")}}</span>
    </div>
    <div class="projectListMenu">
      <div class="projectListItem">
        <div class="submenu_link withToggler">
          <span class="small_menu_title level_1"  @click="toggleProject = !toggleProject" >My projects</span>
          <img :src="require('@/assets/open.svg')" @click="toggleProject = true" alt="" v-if="toggleProject == false">
          <img :src="require('@/assets/close.svg')" @click="toggleProject = false" alt="" v-if="toggleProject == true">

        </div>

      </div>
      <div class="projectListSubMenu"  v-if="toggleProject == true">
        <div class="projectListItem " v-for="(project, p) in myProjects" :key="p">
          <router-link :to="'/projet/'+project._id.$oid" :class="(project._id.$oid == activeProject) ? 'submenu_link active_link_by_project' : 'submenu_link'" v-if="project._id" >
            <span class="small_menu_title">{{project.name.toUpperCase()}}</span>
          </router-link>
        </div>
      </div>

      <div class="projectListItem">
        <router-link to="/all_my_projects" class="submenu_link">
          <span class="small_menu_title level_1">My dashboard</span>
        </router-link>
      </div>
      <div class="projectListItem">
        <router-link to="/selectYourChallenge" class="submenu_link">
          <span class="small_menu_title level_1">Create a new project</span>
        </router-link>
      </div>

    </div>


    <div class="menu_link" >
      <img :src="require('@/assets/scoutMenu.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">{{$t("Scout")}}</span>
    </div>
    <div class="projectListMenu">
      <div class="projectListItem">
        <div class="submenu_link withToggler">
          <span class="small_menu_title level_1"  @click="toggleLinkplus = !toggleLinkplus" >{{$t("My Scouting with Link+")}}</span>
          <img :src="require('@/assets/open.svg')" @click="toggleLinkplus = true" alt="" v-if="toggleLinkplus == false">
          <img :src="require('@/assets/close.svg')" @click="toggleLinkplus = false" alt="" v-if="toggleLinkplus == true">

        </div>
</div>

            <div class="projectListSubMenu"  v-if="toggleLinkplus == true">
      <div class="projectListItem " >
        <router-link to="/link_plus" class="submenu_link" >
          <span class="small_menu_title">{{$t("Create")}}</span>
        </router-link>
      </div>
      <div class="projectListItem "  >
        <router-link to="/list_link_plus" class="submenu_link" >
          <span class="small_menu_title">{{$t("Manage")}}</span>
        </router-link>
      </div>
    </div>

      <router-link to="/startups_ecosystem" class="submenu_link">
        <span class="small_menu_title level_1">{{$t("My startup pool")}}</span>
      </router-link>

      <router-link to="/startups_stats" class="submenu_link">
        <span class="small_menu_title level_1">{{$t("My startup funnel")}}</span>
      </router-link>
    </div>



        <div class="menu_link" >
          <img :src="require('@/assets/assessMenu.svg')" class="menu_icon_img" alt="">
          <span class="menu_title">{{$t("AssessMenu")}}</span>
        </div>

        <div class="projectListMenu">
          <div class="projectListItem">
            <div class="submenu_link withToggler">
              <span class="small_menu_title level_1"  @click="toggleAssessment = !toggleAssessment" >{{$t("My startup assessments")}}</span>
              <img :src="require('@/assets/open.svg')" @click="toggleAssessment = true" alt="" v-if="toggleAssessment == false">
              <img :src="require('@/assets/close.svg')" @click="toggleAssessment = false" alt="" v-if="toggleAssessment == true">

            </div>

          </div>
          <div class="projectListSubMenu"  v-if="toggleAssessment == true">

          <div class="projectListItem " >
            <router-link to="/internalassessment_creation" class="submenu_link" >
              <span class="small_menu_title">{{$t("Create")}}</span>
            </router-link>
          </div>
          <div class="projectListItem "  >
            <router-link to="/manage_assessments" class="submenu_link" >
              <span class="small_menu_title">{{$t("Manage")}}</span>
            </router-link>
          </div>
        </div>
        </div>




          <router-link to="/list_filters" class="menu_link">

            <img :src="require('@/assets/filterMenu.svg')" class="menu_icon_img" alt="">
            <span class="menu_title">{{$t("Filter settings")}}</span>
          </router-link>




                      <a href="https://outlook.office365.com/owa/calendar/innopearl@innopearl.ch/bookings/"  target="_blank" class="menu_link">

                    <img :src="require('@/assets/askMenu.svg')" class="menu_icon_img" alt="">
                    <span class="menu_title">{{$t("Ask us")}}</span>
                  </a>
    <!-- <div class="menu_link" >
      <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">My Partner+ Club</span>
    </div>
    <div class="projectListMenu">
      <div class="projectListItem">
        <router-link to="/startups_stats" class="submenu_link">
          <span class="small_menu_title">My dashboard</span>
        </router-link>
      </div>
      <div class="projectListItem">
        <router-link to="/startups_ecosystem" class="submenu_link">
          <span class="small_menu_title">My startup pool</span>
        </router-link>
      </div>
      <div class="projectListItem">
        <div to="/all_my_projects" class="submenu_link">
          <span class="small_menu_title">My projects</span>
        </div>
      </div>
      <div class="projectListItem " v-for="(project, p) in myProjects" :key="p">
        <router-link :to="'/projet/'+project._id.$oid" :class="(project._id.$oid == activeProject) ? 'submenu_link active_link_by_project' : 'submenu_link'" v-if="project._id" >
          <span class="small_menu_title">{{project.name.toUpperCase()}}</span>
        </router-link>
      </div>

        <router-link to="/selectYourChallenge" class="menu_link">
                <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
          <span class="small_menu_title">Create a new project</span>
        </router-link>

    </div>
    <router-link to="/list_filters" class="menu_link">
            <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="small_menu_title">My filters</span>
    </router-link>

    <router-link to="/list_link_plus" class="menu_link">
            <img :src="require('@/assets/menu_my_ecosystem.svg')" class="menu_icon_img" alt="">
      <span class="small_menu_title">Link+<sup>©</sup></span>
    </router-link>
      <div class="projectListMenu">
    <div class="projectListItem " >
      <router-link to="/link_plus" class="submenu_link" >
        <span class="small_menu_title">Create</span>
      </router-link>
    </div>

    <div class="projectListItem "  >
      <router-link to="/list_link_plus" class="submenu_link" >
        <span class="small_menu_title">View all</span>
      </router-link>
    </div>
  </div>



    <a href="https://outlook.office365.com/owa/calendar/innopearl@innopearl.ch/bookings/" class="menu_link" target="_blank" >
      <img :src="require('@/assets/menu_ask_us.svg')" class="menu_icon_img" alt="">
      <span class="menu_title">Ask us</span>
    </a>
    <router-link to="/all_my_projects" class="innopearl_topbar_link account_responsive">
      <img :src="this.apiUrl+'/get_image/'+$store.state.customerlogo+'?token='+$store.state.innotoken" v-if="$store.state.customerlogo != null" alt="" class="logoImage">

      <img :src="require('@/assets/header_my_account.svg')" alt="" v-else/>
      <span class="username">{{fullname}}</span>
    </router-link> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'MenuSidebar',
  props: ['addAccount'],
  components: {
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      activeProject:null,
      activelink:null,
      toggleProject : false,
      toggleLinkplus : false,
      toggleAssessment: false,
      toogleAdminPan:false,
      // menuItems: [
      //   {
      //     href: '/all_my_projects',
      //     title: 'My ecosystem',
      //     icon: require('@/assets/menu_my_ecosystem.svg'),
      //     isProjectContainer: true,
      //   },
      //   {
      //     href: 'https://outlook.office365.com/owa/calendar/innopearl@innopearl.ch/bookings/',
      //     title: 'Ask us',
      //     icon: require('@/assets/menu_ask_us.svg'),
      //     target: "ext"
      //   },
      // ],
      // menuItemsAdmin: [
      //   {
      //     href: '/all_my_projects',
      //     title: 'My ecosystem',
      //     icon: require('@/assets/menu_my_ecosystem.svg'),
      //     isProjectContainer: true,
      //   },
      //   {
      //     href: '/register_admin',
      //     title: 'Add an admin',
      //     icon: require('@/assets/menu_ask_us.svg'),
      //   },
      // ],
      // menuItemsPlus: [
      //   {
      //     href: '/all_my_projects',
      //     title: 'My account',
      //     icon: require('@/assets/header_my_account.svg'),
      //   },
      //
      // ],
    }
  },
  mounted(){
    if(this.$route.params.id ){
         this.activeProject = this.$route.params.id;
    }
    else if(this.$route.params.projectid){
         this.activeProject = this.$route.params.projectid;
    }
    else if(this.$route.params.pid){
      this.activeProject = this.$route.params.pid;
      this.activelink = this.$route.params.id;
    }
    else if(this.$route.params.lid ){
         this.activelink = this.$route.params.id;
    }
    else{
      this.activeProject = null;
    }
  },
  watch:{
   $route (to, from){
       console.log(to.params);
       if(to.params.id ){
            this.activeProject = to.params.id;
            this.activelink = this.$route.params.id;
       }
       else if(to.params.projectid){
            this.activeProject = to.params.projectid;
       }
       else if(to.params.pid){
         this.activeProject = to.params.pid;
       }
       else if(to.params.lid ){
            this.activelink = to.params.lid;
       }
       else{
         this.activeProject = null;
       }
       console.log(from);
   },
 },
    computed: mapState(['role',"fullname",'myProjects','myLinksPlus']),

}
</script>

<style lang="css" scoped>

.sidebar_menu{
  display: flex;
  flex-direction: column;
}

.menu_link{
  border: 1px solid #3783C5;
    border-radius: 200px;
    padding: 8px 15px;
    color: #3783C5;
    align-items: center;
    display: flex;
    gap: 20px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0px;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 10px;
    margin-top:50px;
}
.menu_link:first-child{
  margin-top:10px;
}
.menu_item{
  padding-bottom: 10px;
border-bottom: 1px solid #C2C4C6;
}

.projectListMenu{
  display:flex;
  flex-direction: column;
  gap: 20px;
  margin-top:10px;
  margin-bottom: 10px;
}
.projectListSubMenu{
  display:flex;
  flex-direction: column;
  gap: 10px;

}
.submenu_link{
  display: flex;
  margin-left:10px;
  font-size: 15px;
  line-height: 16px;
letter-spacing: 0px;
color: #000000;
text-decoration: none;
font-weight: 300;
}


.menu_icon_img{
  width: 30px !important;
  height:35px !important;
  object-fit: contain;
}




.account_responsive{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size :10px;
  line-height: 12px ;
letter-spacing: 0px;
color: #3783C5;
text-transform: uppercase;
text-decoration: none;
margin-top:20px;
}
.account_responsive img{
width: 30px;
}
@media screen and (min-width:1200px){
  .account_responsive{
    display: none;
  }
}

.active_link_by_project,
.active_link_by_project *{
color:#3783C5 !important;
font-weight: bold;
}
.logoImage{
  width: 50px !important;
  height:50px;
  object-fit:contain;
  border-radius: 50%;
}


.menu_title{
  color: #3783C5;
  font-size:14px;
  font-weight: 500;

}
.small_menu_title{
  font-size:14px;
}
.small_menu_title.level_1{
color:#000;
font-weight: 500;
text-transform: uppercase;
}
.menuDivider{
  width:100%;
  height:1px;
  background:#C2C4C6;
  margin-top:30px;
  margin-bottom: 30px;
}

.withToggler{
  display:flex;
  justify-content: space-between;
  cursor:pointer;
}

.mainMenuLink{
padding-top:10px;
padding-bottom: 10px;
}
</style>
